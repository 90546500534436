import React from 'react';
import data from '../data/data.json';
import { Helmet } from 'react-helmet';
import favicon from '../assets/img/favicon.png';
import Accordion from 'react-bootstrap/Accordion';

export default function FAQ() {
  return (
    <>
      <Helmet>
        <meta name="icon" href={favicon} />
        <link rel="apple-touch-icon" sizes="57x57" href={favicon} />
        <link rel="apple-touch-icon" sizes="60x60" href={favicon} />
        <link rel="apple-touch-icon" sizes="72x72" href={favicon} />
        <link rel="apple-touch-icon" sizes="76x76" href={favicon} />
        <link rel="apple-touch-icon" sizes="114x114" href={favicon} />
        <link rel="apple-touch-icon" sizes="120x120" href={favicon} />
        <link rel="apple-touch-icon" sizes="144x144" href={favicon} />
        <link rel="apple-touch-icon" sizes="152x152" href={favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
        <link rel="icon" type="image/png" sizes="192x192" href={favicon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
        <link rel="icon" type="image/png" sizes="96x96" href={favicon} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
        <title>TruLav FAQ | Jose Upmind</title>
      </Helmet>
      <div className="container-fluid my-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className='text-center'>F A Q</h3>
              <h5 className="text-uppercase text-center">PT Jose Upmind Digital Optima</h5>
            </div>
            <div className="col-lg-12">
              <div className="mt-3">
                {data.datas.faq.items.map((item, index) => (
                    <Accordion className='my-3' key={index}>
                    <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      <p style={{fontSize: "13px", lineHeight: 1.5}}>
                        {item.Q}
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html:item.A}} style={{fontSize: "12px", lineHeight: 1.5}}/>
                    </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                ))}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-5">
                <p
                  className="text-center fw-light"
                  style={{ fontSize: '14px' }}
                >
                  Copyright © 2016 - 2024 PT Jose Upmind Digital Optima
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
